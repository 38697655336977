import { Text } from "@clipboard-health/ui-react";
import { Box, type Theme } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

// If we need to add more sizes, we can add them here. At the time of writing the component, there are no designs for other sizes
export interface IconWithCountProps {
  iconType: IconType;
  count: number;
  color?: (theme: Theme) => string | undefined;
}

export function IconWithCount(props: IconWithCountProps) {
  const { iconType, count, color } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <CbhIcon type={iconType} size="medium" sx={{ color }} />

      <Text variant="body2" color={color}>
        {count}
      </Text>
    </Box>
  );
}
