import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type SxProps, type Theme } from "@mui/material";
import { Fragment, type ReactNode } from "react";

interface DotSeparatedListProps {
  children: ReactNode | ReactNode[];
  /**
   * Forces the children to be inline so they should nicely wrap over multiple lines.
   */
  inline?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * Renders a list of children separated by a dot.
 */
export function DotSeparatedList(props: DotSeparatedListProps) {
  const { children, inline = true, sx } = props;

  const filteredChildren = (Array.isArray(children) ? children.filter(Boolean) : [children]).filter(
    Boolean
  );

  return (
    <Box
      component="span"
      sx={mergeSxProps(
        {
          display: "inline-block",

          "> *": {
            verticalAlign: "middle",
          },

          ...(inline && {
            "> *:not(.dot-separator)": {
              display: "inline",
            },
          }),

          ...(!inline && {
            display: "inline-flex",
            alignItems: "center",
          }),
        },
        sx
      )}
    >
      {filteredChildren.map((child, index) => (
        <Fragment
          key={
            typeof child === "string" || typeof child === "number"
              ? child.toString()
              : `dot-element-${index}`
          }
        >
          {child}
          {index < filteredChildren.length - 1 && (
            <Box
              component="span"
              className="dot-separator"
              sx={{
                display: "inline-block",
                width: "0.125rem",
                height: "0.125rem",
                borderRadius: "50%",
                backgroundColor: "currentColor",
                flexShrink: 0,
                marginInline: 2,
              }}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
}
