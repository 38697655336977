import { Text, type TextProps } from "@clipboard-health/ui-react";

import { TextSkeleton } from "./TextSkeleton";

export interface DistanceTextProps extends TextProps {
  formattedDistance?: string;
  isLoading?: boolean;
  isError?: boolean;
  skeletonWidth?: string;
}

export function DistanceText(props: DistanceTextProps) {
  const {
    formattedDistance,
    isLoading,
    isError,
    variant = "caption",
    color,
    skeletonWidth = "15ch",
    ...textProps
  } = props;

  if (isLoading) {
    return <TextSkeleton variant={variant} width={skeletonWidth} />;
  }

  /**
   * Hide distance errors silently since:
   * 1. Distance info is non-critical
   * 2. Showing errors could negatively impact user experience
   * 3. Users can still proceed without this information
   */
  if (isError) {
    return null;
  }

  return (
    <Text variant={variant} color={color} {...textProps}>
      {formattedDistance}
    </Text>
  );
}
