import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
  type SxProps,
  type Theme,
} from "@mui/material";

import { type IconButtonSize, type IconButtonVariant } from "../theming";
import { ButtonInternalLink } from "./ButtonInternalLink";
import { CbhIcon, type IconType } from "./CbhIcon";

interface IconButtonProps extends Omit<MuiIconButtonProps, "color" | "invert"> {
  variant: IconButtonVariant;
  iconType: IconType;
  invert?: boolean;
  size?: IconButtonSize;
  // for some reason, MUI's IconButton props doesn't include href. However, when passed down to the component, it works as expected
  href?: string;
  /**
   * Sets aria-selected to true. This is useful when you need to force visual active state
   * We do this for some flows that buttons look selected when some options were picked by user
   * (e.g. when user sets a filter in shift discovery, the button in header looks selected)
   */
  isSelected?: boolean;
  sx?: SxProps<Theme>;
}

const iconButtonVariantOverrides: Record<IconButtonVariant, MuiIconButtonProps["color"]> = {
  contained: "primary",
  outlined: "secondary",
  outlinedHeavy: "secondary",
};

const iconButtonOutlineThicknessOverrides: Record<
  IconButtonVariant,
  MuiIconButtonProps["outlineThickness"]
> = {
  contained: "regular",
  outlined: "regular",
  outlinedHeavy: "thick",
};

/**
 * A wrapper around MuiIconButton that limits variants to our custom ones and adds our custom icon handling
 * NOTE: Provide `href` to make the button render as an anchor tag. It will support react-router redirecs
 */

export function IconButton(props: IconButtonProps) {
  const {
    variant = "contained",
    iconType,
    size = "medium",
    onClick,
    isSelected,
    "aria-selected": ariaSelected,
    invert,
    ...restProps
  } = props;

  return (
    <MuiIconButton
      outlineThickness={iconButtonOutlineThicknessOverrides[variant]}
      aria-selected={ariaSelected ?? isSelected}
      invert={invert ? "true" : undefined}
      color={iconButtonVariantOverrides[variant]}
      size={size}
      LinkComponent={ButtonInternalLink}
      onClick={onClick}
      {...restProps}
    >
      <CbhIcon type={iconType} />
    </MuiIconButton>
  );
}
