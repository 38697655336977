import { mergeSxProps } from "@clipboard-health/ui-react";
import {
  Divider as MuiDivider,
  type DividerProps as MuiDividerProps,
  type Theme,
} from "@mui/material";

export interface DividerProps extends Omit<MuiDividerProps, "color"> {
  color?: keyof NonNullable<Theme["palette"]["border"]>;
}

export function Divider(props: DividerProps) {
  const { sx, color = "subtle", ...restProps } = props;

  return (
    <MuiDivider
      {...restProps}
      sx={mergeSxProps(
        (theme) => ({
          borderColor: theme.palette.border?.[color],
        }),
        sx
      )}
    />
  );
}
